<template>
  <v-dialog v-model="dialog" max-width="800" persistent>
    <v-card>
      <v-card-title>
        Preencha os dados para completar o agendamento
      </v-card-title>
      <v-card-text class="mt-3">
        <v-form ref="form" @keyup.native.enter="submit">
          <v-text-field
            v-model="userData.document"
            :rules="[$rules.required]"
            class="mb-5"
            persistent-hint
            outlined
            label="Documento pessoal"
            hint="RG para Nacionalidade Brasileira ou documento do seu país atual"
            maxlength="20"
          />
          <v-text-field
            v-model="userData.phone"
            v-mask="['(##)####-####', '(##)#####-####']"
            :rules="[$rules.required]"
            outlined
            label="Telefone para contato"
          />
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex flex-column">
        <v-btn
          block
          rounded
          elevation="0"
          large
          color="secondary"
          class="px-5 mb-3"
          @click="submit"
        >
          <v-icon class="mr-2">save</v-icon>
          Salvar dados
        </v-btn>
        <v-btn
          block
          rounded
          elevation="0"
          outlined
          large
          color="secondary"
          class="px-5"
          @click="close"
        >
          <v-icon class="mr-2">close</v-icon>
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { updateUserDataToAppoint } from "@/services/external/users-service";

export default {
  name: "DialogFormUserData",
  data: () => ({
    userData: {},
  }),
  computed: {
    dialog: {
      set(value) {
        this.$store.commit("appointments/patient/setDialogFormUserData", value);
      },
      get() {
        return this.$store.state.appointments.patient.dialogFormUserData;
      },
    },
  },
  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;
      try {
        await updateUserDataToAppoint({
          document: this.userData.document,
          phone: this.userData.phone,
        });
        this.close();
        this.$toasted.global.success({
          message: "Dados atualizados com sucesso, confirme o agendamento!",
        });
      } catch (e) {
        this.$errorHandler(e);
      }
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>

<style scoped></style>
